import styled from 'styled-components'

export const Wrapper = styled.form`
  width: 100%;

  .MuiOutlinedInput-root {
    border: none;
    border-bottom: none;
    border-radius: 30px;
    outline: none;
    background: white;
  }

  .MuiAutocomplete-input {
    padding-left: 16px !important;
  }

  .MuiAutocomplete-input::placeholder {
    color: black;
  }

  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border: none;
    border-bottom: none;
  }
`
export const IconWrapper = styled.span`
  width: 16px;
  height: 16px;
  margin-left: 16px;
  color: ${({ theme }) => theme.color.primary.medium};
`

export const SearchItem = styled.button`
  background-color: transparent;
  outline: none;
  border: none;
  width: 100%;
  display: flex;
  gap: 12px;
  align-items: center;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 4px;
  border: 1px solid transparent;

  &:hover {
    background-color: #cdcdcd;
  }

  &:active {
    background-color: transparent;
    border: 1px solid #cdcdcd;
  }
`

export const SearchAvatar = styled.img`
  height: 24px;
  width: 24px;
  border-radius: 4px;
  object-fit: cover;
`

export const SearchTitle = styled.div`
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
  letter-spacing: 0.25px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
