import { StartupSkeleton } from './components/StartupSkeleton'
import AppContainer from './containers/App'
import { AnalyticsProvider } from './contexts/analytics.context'
import AuthContext, { AuthProvider } from './contexts/auth.context'

function App() {
  return (
    <AuthProvider>
      <AuthContext.Consumer>
        {({ isStarted }) =>
          isStarted ? (
            <AnalyticsProvider>
              <AppContainer />
            </AnalyticsProvider>
          ) : (
            <StartupSkeleton />
          )
        }
      </AuthContext.Consumer>
    </AuthProvider>
  )
}

export default App
