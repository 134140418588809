import { createContext, ReactNode } from 'react'
import { saveEvent as createEvent } from '../services/analytics/analytics.service'

export enum Event {
  VIEW_PRODUCT = 'view_product',
  ADD_TO_CART = 'add_to_cart',
  START_CHECKOUT = 'start_checkout',
  PURCHASE = 'purchase',
  CLICK = 'click',
  WEBSITE_VIEW = 'website_view',
}

export enum Origin {
  CATALOG = 'catalog',
}

export enum StockOrigin {
  VIRTUAL = 'virtual',
  PHYSICAL = 'physical',
}

export interface EventProps {
  event: Event
  origin: Origin
  stockOrigin?: StockOrigin
  ean?: string
  productName?: string
  orderId?: string
  quantityItems?: number
  unitPrice?: number
  orderValue?: number
}

interface AnalyticsContextData {
  saveEvent: ({ event, origin }: EventProps) => Promise<void>
}

const AnalyticsContext = createContext({} as AnalyticsContextData)

const AnalyticsProvider = ({ children }: { children: ReactNode }) => {
  async function saveEvent(event: EventProps) {
    await createEvent(event)
  }

  return <AnalyticsContext.Provider value={{ saveEvent }}>{children}</AnalyticsContext.Provider>
}
export type {AnalyticsContextData}
export { AnalyticsContext, AnalyticsProvider }
