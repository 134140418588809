import Address from '../interfaces/address'
import DeliveryFee from '../interfaces/deliveryFee'
import { DeliveryRegions, GetDeliveryRegions } from '../services/delivery/response.interface'

const formatString = (str: string) => str.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/\s/g, "")

export function getExpensiveRegion({ regions }: GetDeliveryRegions, address?: Address) {
  const deliveryFees: DeliveryFee[] = []

  regions.map((value) => value.deliveryFees.map((deliveryFee) => {
    if (formatString(deliveryFee.neighborhood.name) === formatString(address?.neighborhood.name || '')) {
      deliveryFees.push(deliveryFee)
      return true
    }
    return false
  })
  )

  const data = deliveryFees[0]

  return data
}

export function getCheapestRegion({ regions }: GetDeliveryRegions) {
  const deliveryFees: DeliveryFee[] = []
  regions.map((value) => value.deliveryFees.map((deliveryFee) => deliveryFees.push(deliveryFee)))

  deliveryFees.sort((a, b) => (a.freeFrom > b.freeFrom ? 1 : -1))
  return deliveryFees.shift()
}

export function getCheapeastNeighborhood(region: DeliveryRegions) {
  return region.deliveryFees
    .map((value) => value)
    .sort((a, b) => a.feePrice - b.feePrice)
    .shift()
}

export function getExpensiveMinimumPurchaseNeighborhood(region: DeliveryRegions) {
  return region.deliveryFees
    .map((value) => value)
    .sort((a, b) => (a.minimumPurchase < b.minimumPurchase ? 1 : -1))
    .shift()
}