import * as yup from 'yup'

export const AddressValidatorBase = {
  number: yup.string().required('Número obrigatório'),
  neighborhood: yup.object({
    name: yup.string().required('Bairro obrigatório'),
    city: yup.object({
      name: yup.string().required(),
      state: yup.object({
        name: yup.string().required(),
      }),
    }),
  }),
  street: yup.string().required('Rua obrigatório'),
  complement: yup.string(),
  isMain: yup.bool(),
}

export const AddressValidatorRequiredCep = {
  ...AddressValidatorBase,
  postcode: yup.string()
    .required('CEP obrigatório')
    .matches(/^[0-9]{5}-?[0-9]{3}$/, 'O CEP digitado é invalido'),
}

export const AddressValidator = {
  ...AddressValidatorBase,
  postcode: yup.string()
    .required('CEP obrigatório')
    .matches(/^[0-9]{5}-?[0-9]{3}$/, 'O CEP digitado é invalido'),
}
