import { siteApi } from '../../config/api'
import { EventProps } from '../../contexts/analytics.context'

export interface SaveEventResponse {
  message?: string
  error?: string
}

export async function saveEvent(event: EventProps) {
  return siteApi.post<SaveEventResponse>(`/v2/analytics/event`, event)
}
