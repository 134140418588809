import React, { ReactNode } from 'react'

import { Stack } from '@mui/material'
import { Tag } from './styles'

interface Props {
  label: string
  border?: string
  block?: boolean
  color: string
  icon?: ReactNode
  background: string
}

const CustomTag: React.FC<Props> = ({
  label = '',
  icon,
  border,
  block = true,
  color,
  background,
}) => {
  return (
    <Tag color={color} block={block} background={background} border={border}>
      <Stack
        gap="4px"
        justifyContent="center"
        alignItems="center"
        flexDirection="row"
        style={{
          fontWeight: '500',
        }}
      >
        {icon}
        {label}
      </Stack>
    </Tag>
  )
}

export default CustomTag
