import Cupom from './cupom'
import Product from './product'

export interface CartProduct {
  product: Product
  quantity: number
}
export type CartOrigin = 'ECOMMERCE' | 'INTEGRATION'
export enum EnumIntegrationType {
  XMARTSHELF = 'XMARTSHELF',
}

export default interface Cart {
  _id?: string
  cupom: Cupom | null
  customerId?: string
  products: CartProduct[]
  fingerprint: string | null
  origin?: CartOrigin
  integration?: EnumIntegrationType
}
