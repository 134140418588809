import React, { useContext, useEffect, useState } from 'react'
import AuthContext from '../../contexts/auth.context'
import { useNavigate } from 'react-router'
import { SearchIcon } from '../../assets/icons'

import PlaceholderImage from '../../assets/ilustration/ProductImageExample.svg'
import { Wrapper, IconWrapper, SearchAvatar } from './styles'
import { SearchItem, SearchTitle } from './styles'

import { SearchPaper } from '../SearchPaper'
import { AutocompleteInputChangeReason, Autocomplete, TextField, debounce } from '@mui/material'
import {
  saveProductHistory,
  saveQueryHistory,
} from '../../services/searchHistory/searchHistory.service'
import { useEC } from '../../hooks/useEC'
import { useSearch } from '../../hooks/useSearch'
import { useSearchParams } from 'react-router-dom'
import Product from '../../interfaces/product'
import { CDN } from '../../config/keys'

export const Search = () => {
  const { store } = useContext(AuthContext)
  const { products = [], search, clearSearch } = useSearch()
  const navigate = useNavigate()
  const { addImpression } = useEC()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams] = useSearchParams()
  const [open, setOpen] = useState(false)

  const onInputChange = debounce(
    async (
      event: React.SyntheticEvent<Element, Event>,
      value: string,
      reason: AutocompleteInputChangeReason
    ) => {
      if (value && value.trim().length > 0) {
        return await search(value)
      }
      clearSearch()
      navigate('/produtos', { replace: true })
    },
    400
  )

  const onSubmitForm = async (event: any) => {
    event.preventDefault()
    const term = String(event.target[0].value)

    if (term && term.trim().length > 0) {
      await search(term)

      setTimeout(() => {
        setOpen(false)
        navigate(`/produtos?q=${term}`)
      }, 500)

      if (document.activeElement) (document.activeElement as any).blur()
      window.scrollTo(0, 0)
      saveQueryHistory(term)
    }
  }

  const onClickSearchItem = (option: Product) => {
    saveProductHistory(option)
    setOpen(false)
    if (document.activeElement) (document.activeElement as any).blur()
    navigate(`/produtos/${option.slug}${option.updateOrigin === 'Docas' ? '?v=1' : ''}`)
    window.scrollTo({ top: 0 })
  }

  useEffect(() => {
    if (products) addImpression(products.slice(0, 10), 'search-input')
  }, [addImpression, products])

  return (
    <Wrapper action="." onSubmit={onSubmitForm} role="search">
      <Autocomplete
        open={open && !searchParams.get('q')}
        onOpen={() => setOpen(true)}
        onClose={() => {
          setOpen(false)
          clearSearch()
        }}
        renderOption={(props, option) => (
          <SearchItem key={option.ean} onClick={() => onClickSearchItem(option)}>
            <SearchAvatar
              src={
                new URL(option.image?.icon ? option.image.icon : option.image?.key, CDN.image).href
              }
              onError={(e) => (e.currentTarget.src = PlaceholderImage)}
            />
            <SearchTitle>
              {option.name} {option.presentation || ''}
            </SearchTitle>
          </SearchItem>
        )}
        PaperComponent={({ children }) => <SearchPaper onClose={() =>  setOpen(false)} children={children} />}
        fullWidth
        noOptionsText={''}
        disableCloseOnSelect
        clearOnBlur={false}
        getOptionLabel={(op) => op.name}
        onInputChange={onInputChange}
        options={products.slice(0, 6)}
        disableListWrap
        filterOptions={(options) => options}
        popupIcon={<React.Fragment />}
        renderInput={(params) => (
          <div ref={params.InputProps.ref}>
            <TextField
              {...params}
              type="search"
              placeholder={`Busque na ${store?.name}`}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <IconWrapper>
                    <SearchIcon />
                  </IconWrapper>
                ),
              }}
              onKeyDown={(event: any) => {
                if (event.key === 'Enter') {
                  const query = String(event.target.value)

                  if (query && query.trim().length > 0) {
                    navigate(`/produtos?q=${query}`)
                  }
                }
              }}
            />
          </div>
        )}
      />
    </Wrapper>
  )
}
